@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-Bold.woff2) format('woff2'), url(/fontmodules/OpenSans-Bold.woff) format('woff'), url(/fontmodules/OpenSans-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-BoldItalic.woff2) format('woff2'), url(/fontmodules/OpenSans-BoldItalic.woff) format('woff'), url(/fontmodules/OpenSans-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: OpenSansCond;
    src: url(/fontmodules/OpenSans-CondBold.woff2) format('woff2'), url(/fontmodules/OpenSans-CondBold.woff) format('woff'), url(/fontmodules/OpenSans-CondBold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: OpenSansCond;
    src: url(/fontmodules/OpenSans-CondLight.woff2) format('woff2'), url(/fontmodules/OpenSans-CondLight.woff) format('woff'), url(/fontmodules/OpenSans-CondLight.ttf) format('truetype');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: OpenSansCond;
    src: url(/fontmodules/OpenSans-CondLightItalic.woff2) format('woff2'), url(/fontmodules/OpenSans-CondLightItalic.woff) format('woff'), url(/fontmodules/OpenSans-CondLightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-ExtraBold.woff2) format('woff2'), url(/fontmodules/OpenSans-ExtraBold.woff) format('woff'), url(/fontmodules/OpenSans-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-ExtraBoldItalic.woff2) format('woff2'), url(/fontmodules/OpenSans-ExtraBoldItalic.woff) format('woff'), url(/fontmodules/OpenSans-ExtraBoldItalic.ttf) format('truetype');
    font-weight: 800;
    font-style: italic
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-Italic.woff2) format('woff2'), url(/fontmodules/OpenSans-Italic.woff) format('woff'), url(/fontmodules/OpenSans-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-Light.woff2) format('woff2'), url(/fontmodules/OpenSans-Light.woff) format('woff'), url(/fontmodules/OpenSans-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-LightItalic.woff2) format('woff2'), url(/fontmodules/OpenSans-LightItalic.woff) format('woff'), url(/fontmodules/OpenSans-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-Regular.woff2) format('woff2'), url(/fontmodules/OpenSans-Regular.woff) format('woff'), url(/fontmodules/OpenSans-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-Semibold.woff2) format('woff2'), url(/fontmodules/OpenSans-Semibold.woff) format('woff'), url(/fontmodules/OpenSans-Semibold.ttf) format('truetype');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: OpenSans;
    src: url(/fontmodules/OpenSans-SemiboldItalic.woff2) format('woff2'), url(/fontmodules/OpenSans-SemiboldItalic.woff) format('woff'), url(/fontmodules/OpenSans-SemiboldItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic
}